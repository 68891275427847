import tw, { styled, css } from "twin.macro"

export const Nav = styled.nav`
  ${tw`mx-0 -mb-7.5 md:mb-0 md:pb-0 overflow-y-hidden overflow-x-auto lg:overflow-hidden transition-all duration-700 lg:duration-200 invisible lg:visible`}
  ${({ isMobile }) =>
    isMobile
      ? css`
          transform: translateX(-110%);
          max-width: 600px;
        `
      : css`
          width: 100%;
          display: flex;
        `} 
  ${({ isMobile }) => isMobile && tw`fixed bg-white w-screen overflow-y-auto flex flex-col justify-between`} 
  ${({ isActive }) => isActive && tw`z-[21] left-0 visible`}
  ${({ isActive }) =>
    isActive &&
    css`
      transform: translateX(0);
    `}
  ${({ isMobile, headerHeight }) =>
    isMobile &&
    headerHeight &&
    css`
      top: ${!isMobile ? headerHeight : headerHeight - 1}px;
      height: calc(100vh - ${headerHeight}px);
    `}
  @media (max-width: 767px) {
    white-space: nowrap;
    overflow: auto;
  }
`

export const Items = styled.ul`
  ${tw`flex md:justify-center items-center`}
  ${({ isMobile, secondary }) => (isMobile || secondary) && tw`flex-col items-start px-5`}
  ${css`
    @media (min-width: 1024px) {
      li:first-child {
        padding: 0 0.69rem;
      }

      li:last-child {
        padding-right: 0.69rem;
      }

      li + li {
        padding: 0 0.69rem 0 0.69rem;
      }
    }
  `}
  ${({ isMobile, padForMouseOver }) =>
    !isMobile &&
    padForMouseOver &&
    css`
      li {
        padding-top: calc(0.5rem + 2px) !important;
        padding-bottom: calc(0.9375rem + 11px) !important;
      }
    `}
`
export const Item = styled.li`
  ${({ isMobile }) => isMobile && tw`w-full py-[1.125rem] lg:p-0`}
  ${({ secondary }) => secondary && tw`py-2`}
  ${({ isMobile, isLast }) =>
    isMobile &&
    !isLast &&
    css`
      border-bottom: 1px solid #f7eff6;
    `}
`
export const Link = styled.a`
  ${tw`block text-sm mr-8.5 md:mr-0 whitespace-nowrap relative hover:cursor-pointer`}
  ${({ isMobile }) => isMobile && tw`flex justify-between w-full`}
`

export const MultiColourText = styled.span`
  ${tw`uppercase font-bold`}
  background-image: linear-gradient(to left, purple, violet, red, red, orange, yellow, green);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
`

export const SecondaryContainer = styled.div`
  ${tw`py-5`}
  ${css`
    background: #f8f8f8; ;
  `}
`

export const IconContainer = styled.span`
  ${tw`ml-2`}
  ${css`
    svg {
      display: inline;
    }
  `}
`
